(function ($) {
  var stickyColumns = $('.js-non-loyalty .sticky-footer-cols');
  var columnCount = $('.js-non-loyalty .sticky-footer-cols').length;

  if (columnCount === 3) {
    stickyColumns.addClass('mac-3-column-layout');
  } else if (columnCount === 2) {
    stickyColumns.addClass('mac-2-column-layout');
  }

  $('.sticky-footer-cols').each(function () {
    var selectedLayout = $(this).data('display-layout');

    if (selectedLayout) {
      $(' .' + selectedLayout, this).show();
    }
  });
})(jQuery);
